import logo from './logo.svg';
import './App.css';
import {Routes, Route} from "react-router-dom";
import Layout from './components/Layout';
import Home from './components/Home';
import NotFound from './components/NotFound';
import Dashboard from './components/Dashboard.js';
import Test from './components/Test';
function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout/>}>
        <Route index element={<Home/>}/>
        <Route path='dashboard/'>
          <Route index element={<Dashboard/>}/>
          <Route path=":guildID" element={<Test/>}/>
        </Route>
        <Route path="*" element={<NotFound/>}/>
      </Route>
    </Routes>
  );
}

export default App;
