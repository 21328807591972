import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import GuildCard from "./sub-components/GuildCard";
export default function Dashboard(){
    const cookies = new Cookies();
    const navigate = useNavigate();
    const [token, setToken] = useState("");
    const [guilds, setGuilds] = useState([]);
    console.log(cookies.get('token'));
    const getGuilds = async() => {
        console.log(token);
        if(!cookies.get('token')){
            navigate('/');
        }else{
            fetch('/api/getUserGuilds', {method:"POST", headers:{'Accept': 'application/json', 'Content-Type':'application/json'}, body:JSON.stringify({token:cookies.get('token')})}).then(async(res)=>{
                const resobj = await res.json();
                console.log(resobj);
                setGuilds(resobj);
            });
        }
    };
    useEffect(() => {
        getGuilds();
    }, []);
    return(
        <>
            <div className="guilds">
                {Array.isArray(guilds) ? guilds && guilds.map((guild)=><GuildCard guildName={guild.name} guildID={guild.id} guildAvatar={guild.icon ? `https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}` : 'https://www.clker.com/cliparts/F/g/8/N/k/X/black-question-mark-square-icon-hi.png'}/>) : <h1>loading</h1>}
            </div>
        </>
    )
}