import { Link, NavLink } from "react-router-dom";
import logo from "./img/logo.jpg"
import Cookies from 'universal-cookie';
import { useEffect, useState } from "react";
export default function Navbar(){
    const cookies = new Cookies();
    const token = cookies.get('token');
    const [userdata, setUserData] = useState({});
    console.log(userdata);
    const getUserData = async () =>{
        if(!token){
            return null;
        }
        if(!userdata.id && !userdata.avatar){
            const res = await fetch("/api/getUserData", {method:'POST', headers: { 'Content-Type': 'application/json' }, body:JSON.stringify({token:token})});
            const resobj = await res.json();
            setUserData(resobj);
        }
    };
    useEffect(() =>{
        getUserData();
    }, []);
    
    return(
        <nav>
            <ul>
                <li>
                    <img src={logo} className="logo-nav" alt="Texturn Discord Bot"/> 
                </li>
                <li>
                {userdata.avatar ? <><NavLink style={{"marginRight":"100px"}} className='nav-element' to="/dashboard">Dashboard</NavLink><img src={`https://cdn.discordapp.com/avatars/${userdata.id}/${userdata.avatar}`}  id="userlogo"/></> : <Link className="nav-element" to="https://discord.com/api/oauth2/authorize?client_id=1193227177181843577&response_type=code&redirect_uri=https%3A%2F%2Ftexturn.xyz%2Fapi%2Fdiscord%2Fcallback&scope=identify+guilds+guilds.join">Login</Link>}
                </li>
            </ul>
        </nav>
    );
}