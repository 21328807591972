import { useParams } from "react-router-dom";
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

export default function Test(){
    const cookies = new Cookies();
    const navigate = useNavigate();
    const [token, setToken]=useState();
    const {guildID} = useParams();
    console.log(guildID);
    return(
        <h1>Under Development</h1>
    )
}