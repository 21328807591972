import logo from "./img/logo.jpg"

export default function Home(){
    return(
        <>
            <div className="home">
                <img className="logo" src={logo} alt="Texturn Discord Bot"/>
                <h1>Texturn: An All In One Discord Bot</h1>
                <p>Texturn is a Discord Bot which aims to be an All-In-One solution for discord bots. It aims to have features like <br/><br/>
                <ul>
                    <li>Moderation</li>
                    <li>Fun And Games</li>
                    <li>Music</li>
                </ul> <br/>
                and with a User Friendly Dashboard for users to manage the bot</p>
                <button type="button" id="invite" onClick={() => window.location.href="https://discord.com/api/oauth2/authorize?client_id=1193227177181843577&permissions=8&scope=bot"}>Invite</button>
            </div>
        </>
    );
}